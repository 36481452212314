@import 'normalize.css/normalize.css';
@import "@fontsource/source-code-pro/300.css";

.flex {
    display: flex;
}

.body {
    background-color: white;
    justify-content: center;
    font-family: "Source Code Pro";
    flex-direction: column;
    max-width: 100%;

    height: 200px;
    max-height: 100vh;

    .menu {
        justify-content: flex-end;

        .sign-up {
            padding: 2em;
        }
    }

    .content {
        flex-direction: column;
        color: #212121;

        .title {
            justify-content: center;
            font-size: 4em;
            font-weight: 300;
            height: 100px;
            line-height: 100px;

            .left {
                width: 8em;
                justify-content: flex-end;

                .cursor {
                    margin: 0 -0.2em;
                }
            }

            .right {
                width: 8em;
            }
        }

        .sub-title {
            justify-content: center;
            margin-top: 1em;
        }
    }
}

.blink {
    animation: 1s blink step-end infinite;
}

@keyframes blink {

    from,
    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}
